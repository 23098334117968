<template>
  <div>
    <main class="main-content">
			<div class="alliances-banner_wrap">
				<div class="alliances-banner_inner">
					<div class="container">
						<div class="row">
							<div class="col-md-5 col-sm-12 col-md-offset-1 text-left">
								<div class="header-page">
									<!--
									<h2 class="header-page_title">
										Nuestra nueva alianza
									</h2>
									-->
									<h3 class="header-page_subtitle active color-black">
										PWA Consultores<br>
										Tus aliados en compras
									</h3>
									<div class="header-page_text">
										<p>Consultora que brinda Servicios Integrales para las Áreas de Compras, en continua búsqueda por generar resultados para sus clientes y llevar a las Áreas de Compras hacia otro nivel de excelencia y servicio.
Los miembros de PWA Consultores son Pablo Wagner <a href="https://www.linkedin.com/in/pablowagner/" target="_blank"><i class="fa fa-linkedin-square" aria-hidden="true" style="font-size:20px"></i></a> y Hernán Chiesa <a href="https://www.linkedin.com/in/hernanchiesa/" target="_blank"><i class="fa fa-linkedin-square" aria-hidden="true" style="font-size:20px"></i></a>, ex Gerentes de Compras que han trabajado muchos años en multinacionales y, por lo tanto, conocen de primera mano las problemáticas actuales del sector de Compras.</p>
										<p><a href="https://www.linkedin.com/company/pwa-consultores/" target="_blank"><i class="fa fa-linkedin-square" aria-hidden="true" style="font-size:20px"></i></a> </p>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<a href="https://pwa.com.ar/" target="_blank"><img src="../assets/images/landing/pwa.png" style="width:400px;" alt="PWA Consultores"></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="container">
					<div class="section-page_wrap" id="valor-amia">
							<div class="row">
									<div class="col-md-6 col-sm-12 text-left">
										<div class="header-page">
											<h3 class="header-page_subtitle active color-black">
												Valor RSE - AMIA/BID/FOMIN<br>
												Gestión sostenible de la cadena de valor
											</h3>
											<div class="header-page_text">
												<p>Valor RSE+Competitividad VALOR es una iniciativa ejecutada por la Asociación Mutual Israelita Argentina - AMIA en Argentina y patrocinada por el Banco Interamericano de Desarrollo (BID), a través del Fondo Multilateral de Inversiones (FOMIN). 
Trabaja desde 2009 con Equipos Internos de Grandes Empresas, y sus proveedores, clientes y distribuidores de las cadenas de valor, brindando asesoramiento, capacitación y asistencia técnica para la implementación y gestión de planes de mejora con un enfoque de Responsabilidad Social Empresaria y Sostenibilidad.
</p>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-sm-12">
										<a href="http://valor.amia.org.ar" target="_blank"><img src="../assets/images/landing/valor-amia.png" style="width:300px;" alt="Valor RSE - AMIA/BID/FOMIN"></a>
									</div>
							</div>
					</div>
					<div class="section-page_wrap" id="cponet">
							<div class="row">
									<div class="col-md-6 col-sm-12 text-left">
										<div class="header-page">
											<h3 class="header-page_subtitle active color-black">
												CPONET<br>
												La ciudad de los negocios
											</h3>
											<div class="header-page_text">
												<p>CPOnet cuenta con 21 mil compradores registrados en España. Medios de comunicación para Compras, convenciones, webinars, formación y GBN: la solución innovadora para homologar proveedores.</p>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-sm-12">
										<a href="https://www.cponet.net" target="_blank"><img src="../assets/images/landing/cponet.png" style="width:300px;" alt="CPONET"></a>
									</div>
							</div>
					</div>
					<div class="section-page_wrap" id="apecroc">
							<div class="row">
									<div class="col-md-6 col-sm-12 text-left">
										<div class="header-page">
											<h3 class="header-page_subtitle active color-black">
												APEPROC<br>
												Asociación Peruana de Profesionales en Compras
											</h3>
											<div class="header-page_text">
												<p>APEPROC es la Asociación de Profesionales en Compras del Perú. Fundada en el 2020, tiene como objetivo la integración, promoción, incentivo y profesionalización de la gestión de Compras y Abastecimiento. La asociación aspira a contribuir en el desarrollo del país mediante la puesta en valor y mejora continua de la importante gestión que se realiza en estas áreas.</p>
												<p><a href="https://www.linkedin.com/company/apeproc/" target="_blank"><i class="fa fa-linkedin-square" aria-hidden="true" style="font-size:20px"></i></a> </p>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-sm-12">
										<img src="../assets/images/landing/apeproc.png" style="width:300px;" alt="APEPROC">
									</div>
							</div>
					</div>
					<div class="section-page_wrap" id="cuartel-de-ventas">
							<div class="row">
									<div class="col-md-6 col-sm-12 text-left">
										<div class="header-page">
											<h3 class="header-page_subtitle active color-black">
												Cuartel de Ventas<br>
												Escuela Internacional de Negocios
											</h3>
											<div class="header-page_text">
												<p>Escuela de Negocios comprometida en la enseñanza, el aprendizaje, la investigación, y el desarrollo de líderes de ventas, generadores de negocios, gerentes de ventas y consultores comerciales exitosos. Provee a sus alumnos la formación y conocimientos que les permita identificar, alcanzar y mantener ventajas competitivas sostenibles para sus negocios y carreras.
Los cursos y certificaciones internacionales tienen por objetivo formar profesionales capaces de desarrollar con éxito un ciclo completo de ventas, aumentando el volumen de negocios y valor de las operaciones de la empresa, asociado a una amplia satisfacción de sus clientes y al reconocimiento del segmento de la industria donde actúa. 
En este momento la escuela cuenta con más de 3000 alumnos activos, de todo Latino América y España.
</p>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-sm-12">
										<a href="https://cuarteldeventas.com" target="_blank"><img src="../assets/images/landing/cuartel-de-ventas.png" style="width:300px;" alt="Cuartel de Ventas"></a>
									</div>
							</div>
					</div>
			</div>
			<!--
			<div class="section-page_wrap">
					<section id="handelbay-background-curva">
					</section>
			</div>
			<section id="benefits">
					<div class="container">
							<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
								<div class="header-page">
									<h3 class="header-page_subtitle active">
										Beneficios
									</h3>
								</div>
								<div class="benefits_wrap">
									<div class="benefits_items">
										<div class="benefits_item">
											<div class="benefits_header">
												<img alt="Servicio de calidad" src="../assets/images/landing/handelbay/Icono_ACOPC-02.png">
											</div>
											<div class="benefits_body">
												<div class="benefits_body-title">
													<h3 class="title">
														Seminario Individual
													</h3>
												</div>
												<div class="benefits_body-description">
													<p>Descuento del 5% en los seminarios abiertos de abastecimiento estratégico ofertados en el calendario de eventos de ACOPC.</p>
												</div>
											</div>
											<div class="benefits_footer">
												<a href="https://www.acopc.com.co/capacitacion/" target="_blank" class="btn benefits_footer-action">
													Ver más
												</a>
											</div>
										</div>
										<div class="benefits_item">
											<div class="benefits_header">
												<img alt="Servicio de calidad" src="../assets/images/landing/handelbay/Icono_ACOPC2-02.png">
											</div>
											<div class="benefits_body">
												<div class="benefits_body-title">
													<h3 class="title">
														Seminario Grupal
													</h3>
												</div>
												<div class="benefits_body-description">
													<p>Descuento del 10% en seminarios privados, dentro de las empresas.  Este modelo es ideal para empresas que cuenten con un gran numero de compradores y los quieran capacitar a todos.</p>
												</div>
											</div>
											<div class="benefits_footer">
												<a href="https://www.acopc.com.co/seminarios-cerrados/" target="_blank" class="btn benefits_footer-action">
													Ver más
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
					</div>
			</section>
			<section id="handelbay-background-curva-bottom">
			</section>

			<div class="container">
					<div class="section-page_wrap">
							<div class="row">
									<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
										<div class="header-page">
											<h3 class="header-page_subtitle active">
												Contacto
											</h3>
										</div>
									</div>
							</div>
							<div class="row">
									<div class="col-md-3 col-sm-4 hidden-xs col-md-offset-2">
											<div class="img-profile">
													<img src="../assets/images/landing/handelbay/handelbay_agenda_profile.png" alt="Si quieres tener más información de los beneficios de esta alianza, ¡contáctanos!" class="img-full-width">
											</div>
									</div>
									<div class="col-md-6 col-sm-7">
											<div class="auth-form_wrap">
													<div class="auth-form_head">
															<div class="auth-form_head-title">
																	<h3 class="title">Si quieres tener más información de los beneficios de esta alianza,
																	¡contáctanos!</h3>
															</div>
													</div>
													<div class="auth-form_body">
															<form action="" class="form-valid">
																	<div class="row">
																			<div class="col-md-6">
																					<div class="form-group">
																							<label for="name">Nombre completo</label>
																							<input type="text" name="name" id="name" required="required" class="floating inputs" title="Nombre requerido">
																					</div>
																			</div>
																			<div class="col-md-6">
																					<div class="form-group">
																							<label for="email">Correo electrónico</label>
																							<input type="email" name="email" id="email" required="required" class="floating inputs" title="Correo invalido." pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$">
																					</div>
																			</div>
																			<div class="col-md-6">
																					<div class="form-group">
																							<label for="phone">Teléfono</label>
																							<input type="text" name="phone" id="phone" required="required" class="floating inputs">
																					</div>
																			</div>
																			<div class="col-md-6">
																					<div class="form-group">
																							<label for="company_name">Nombre de tu empresa</label>
																							<input type="text" name="company_name" id="company_name" required="required" class="floating inputs">
																					</div>
																			</div>
																			<div class="col-md-12">
																					<div class="form-group-check">
																							<div class="squared">
																									<input type="checkbox" required="" id="downloader-privacity">
																									<label class="downloader-privacity" for="downloader-privacity"></label>
																							</div>
																							<div class="check_text inline-block">
																									<div class="titles">
																											<label for="downloader-privacity">
																													Me gustaria recibir información y ofertas sobre HandelBay
																											</label>
																									</div>
																							</div>
																					</div>
																					<div class="auth-form_body-info">
																							<p>Al registrarse está de acuerdo con nuestro Términos de servicio y Política de Privacidad</p>
																					</div>
																					<div class="sendAction">
																							<hr>
																							<button class=" btn btn-handelbay-success">
																								Regístrate
																							</button>
																					</div>
																			</div>
																	</div>
															</form>
													</div>
											</div>
									</div>
							</div>
					</div>
			</div>
			-->
    </main>
  </div>
</template>
<script>
import global from "@/components/Global.vue"

export default {
	data(){
		return{
			global: global
		}
	}
}
</script>